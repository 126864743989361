import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetGroupListQuery } from '../../features/group/groupApi';
import CreateGroup from '../CreateGroup';
import CustomDataGrid from '../CustomDataGrid';
// import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
// import InsertPhotoIconRounded from '@mui/icons-material/InsertPhotoRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
    const [update, setUpdate] = useState(true);
    const [rowId, setRowId] = useState(null);
    const navigate = useNavigate();

    const columns = useMemo(() => [
        { field: 'id', headerName: 'Rank', width: 60, align: 'center', sortable: false, filterable: false, disableColumnMenu: true },
        { field: 'avatar', headerName: 'Avatar', width: 80, disableColumnMenu: true,renderCell: params => params.row.avatar ? <Avatar src={params.row.avatar} /> : <ImageRoundedIcon color='disabled' sx={{ borderRadius: '100%', width: '40px', height: '40px', padding: '5px' }} />, sortable: false, filterable: false },
        { field: 'title', headerName: 'Name', width: 180, sortable: false },
        {
            field: 'totlalMembers',
            headerName: 'Members',
            width: 80, 
            disableColumnMenu: true,
            renderCell: params => params.row.members.length,
            sortable: false,
            filterable: false,
            align: 'center',
        },
        // { field: 'actions', headerName: 'Actions', type: 'actions', renderCell: params => <ViewGroup  {...{ params, rowId, setRowId }} /> },
        {
            field: 'discordInvitationLink',
            headerName: 'Discord Invitation Link',
            width: 170, 
            align: 'center',
            disableColumnMenu: true,
            renderCell: params => params.row?.discordInvitationLink ? <Link  to={params?.row?.discordInvitationLink} target='_blank' style={{ textDecoration: 'none' }}><Button>Join Discord</Button></Link> : "N/A",
            sortable: false,
            filterable: false
        },
    ], [rowId]);

    useGetGroupListQuery();
    const { groupList } = useSelector((state) => state.group);

    const [rankedGroups, setRankedGroups] = useState([]);
    const [newGroups, setNewGroups] = useState([]);

    useEffect(() => {
        if (groupList) {
            const sortedByNewGroups = [...groupList].reverse();
            const sortedByRankGroups = [...groupList].reverse().sort((a, b) => b.members.length - a.members.length);
            const newSortedWithIndexGroups = sortedByNewGroups.map((group, index) => ({ ...group, id: index + 1 }));
            const rankedSortedWithIndexGroups = sortedByRankGroups.map((group, index) => ({ ...group, id: index + 1 }));
            setNewGroups(newSortedWithIndexGroups);
            setRankedGroups(rankedSortedWithIndexGroups);
        }
    }, [groupList, update]);

    const handlRowClick = (params) => {
        console.log(params.row._id);
        navigate(`/groups/${params.row._id}`)
    }


    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>

            <Box
                sx={{
                    maxWidth: '1200px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: {
                        xs: '100%',
                        sm: '100%',
                        md: '70vw',
                        lg: '50vw'
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        variant='h6'
                        component='h6'
                        sx={{ textAlign: 'left', padding: 1, color: '#dcdcdc' }}
                    >
                        Top Ranked Groups
                    </Typography>

                    <CreateGroup setUpdate={setUpdate} />
                </Box>

                <CustomDataGrid data={rankedGroups} columns={columns} handlRowClick={handlRowClick} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        variant='h6'
                        component='h6'
                        sx={{ textAlign: 'left', padding: 1, color: '#dcdcdc' }}
                    >
                        New Groups
                    </Typography>

                    <CreateGroup setUpdate={setUpdate} />
                </Box>

                <CustomDataGrid data={newGroups} columns={columns} handlRowClick={handlRowClick} />


            </Box>
        </Box>
    )
}

export default Home