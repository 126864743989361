import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { updateGroup } from '../../features/group/groupSlice';
import useToast from '../../hooks/useToast';
import AcceptRequest from '../AcceptRequests';
import CustomDataGrid from '../CustomDataGrid';
import DeleteDialog from '../DeleteDialog';
import KickMember from '../KickMember';
import UpdateGroup from '../UpdateGroup';

const GroupDetails = () => {
    const { showToast, errorToast } = useToast("");
    const [group, setGroup] = useState({});
    const params = useParams();
    const localAuth = localStorage.getItem("auth");
    const { user } = JSON.parse(localAuth);

    const naviagte = useNavigate();
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        { field: 'avatar', headerName: 'Avatar', width: 100, renderCell: params => <Avatar src={`https://cdn.discordapp.com/avatars/${params?.row?.dId}/${params?.row?.avatar}.png`} />, sortable: false, filterable: false },
        { field: 'username', headerName: 'Name', width: 170 },
        {
            field: 'actions', headerName: 'Actions', width: 200, type: 'actions', renderCell: params => {
                if (group?.creator === user._id && group?.creator !== params?.row?._id) {
                    return (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            {/* <ViewUser {...{ params }} /> */}
                            <KickMember {...{ params, setUpdate }} />
                        </Box>
                    )
                }
                return (
                    "N/A"
                )
            }
        }
    ], [group?.creator]);
    const navigate = useNavigate();

    useEffect(() => {
        const getGroupDetails = async () => {
            try {
                const response = await axios.get(`https://community-server-livid.vercel.app/api/v1/group/find/${params?.id}`);
                setGroup(response?.data?.group);
            } catch (error) {

                console.log(error)
            }
        }
        getGroupDetails();
    }, [update, params])


    const requestColumns = useMemo(() => [
        { field: 'avatar', headerName: 'Avatar', width: 100, renderCell: params => <Avatar src={`https://cdn.discordapp.com/avatars/${params?.row?.user.dId}/${params?.row?.user.avatar}.png`} />, sortable: false, filterable: false },
        { field: 'displayName', headerName: 'Name', width: 170, renderCell: params => params?.row?.user?.displayName },
        { field: 'date', headerName: 'Date', width: 170, renderCell: params => Date(params?.row?.date).slice(0, 10) },
        {
            field: 'actions', headerName: 'Actions', width: 200, type: 'actions', renderCell: params => <AcceptRequest {...{ params, setUpdate }} />
        }
    ], [group?.creator]);


    const handleJoin = async () => {
        try {
            const response = await axios.put(`https://community-server-livid.vercel.app/api/v1/group/join/${params?.id}`, { user });
            showToast(response?.data?.message);
            setUpdate(prev => !prev);
            dispatch(updateGroup(response.data.group));

        } catch (error) {
            console.log(error);
        }
    }
    const handleLeave = async () => {
        try {
            const response = await axios.put(`https://community-server-livid.vercel.app/api/v1/group/leave/${params?.id}`, { user });
            showToast("Left successfully");
            setUpdate(prev => !prev);
            dispatch(updateGroup(response.data.group));

        } catch (error) {
            console.log(error);
        }
    }

    const handleDelete = async () => {
        try {
            const response = await axios.put(`https://community-server-livid.vercel.app/api/v1/group/delete/${params?.id}`, { user });
            showToast("Deleted successfully")
            setUpdate(prev => !prev);
            naviagte('/');
            window.location.reload(false);
        } catch (error) {
            console.log(error)
        }
    }

    const handleCancel = async () => {
        try {
            const response = await axios.put(`https://community-server-livid.vercel.app/api/v1/group/cancel/${params.id}`, { user, memberToCancel: user._id });
            setUpdate(prev => !prev);
            showToast('Canceled Successfully');
            dispatch(updateGroup(response.data.group));

        } catch (error) {
            console.log(error);
        }
    }

    const handlRowClick = (params) => {
        console.log(params.row._id);
        navigate(`/profile/${params.row._id}`)
    }

    console.log('group?.members', group?.members)

    if (!group.members) return;
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Box sx={{
                maxWidth: '1200px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <Grid container spacing={2} >

                    <Grid item xs={12} lg={9} sx={{ width: '100%', position: 'relative' }}>
                        <Box display={'flex'} sx={{
                            flexDirection: 'row',
                            width: '100%',
                            bgcolor: '#121212', padding: 2, borderRadius: '10px'
                        }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', gap: 0.5 }}>



                                {
                                    group?.avatar && (
                                        <Avatar sx={{ height: { xs: '100px', md: '120px', lg: '120px' }, width: { xs: '100px', md: '120px', lg: '120px' } }} src={group.avatar} />
                                    )
                                }

                                {
                                    !group?.avatar && (
                                        <InsertPhotoIcon sx={{ height: { xs: '100px', md: '120px', lg: '120px' }, width: { xs: '100px', md: '120px', lg: '120px' } }} />
                                    )
                                }




                                <Typography sx={{ color: '#dcdcdc', fontSize: { lg: '2rem', md: '1.5rem', xs: '1.2rem' }, fontWeight: 'bold', textAlign: 'left' }} >{group?.title}</Typography>
                                <Typography sx={{ color: '#dcdcdc', fontSize: { lg: '1.2rem', md: '1rem', xs: '1rem' }, textAlign: 'left', fontWeight: 300 }}>{group?.description}</Typography>

                                {group?.creator === user?._id ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', position: 'absolute', right: 10, top: 30 }}>
                                        <UpdateGroup setUpdate={setUpdate} group={group} />

                                        <DeleteDialog handleDelete={handleDelete} title={group?.title} />
                                    </Box>
                                )
                                    : (
                                        group?.creator !== user?._id && group?.members?.some(member => member?._id === user?._id) ? (
                                            <Button
                                                onClick={handleLeave}
                                                size='small'
                                                sx={{ position: 'absolute', right: 20, top: 30 }}
                                            >
                                                Leave
                                            </Button>
                                        ) : (

                                            group?.joinRequests?.some(request => request.user?._id.toString() === user?._id) ? (
                                                <Button
                                                    onClick={handleCancel}
                                                    size='small'
                                                    sx={{ position: 'absolute', right: 20, top: 30 }}
                                                >
                                                    Cancel Request
                                                </Button>
                                            ) : (

                                                <Button
                                                    onClick={handleJoin}
                                                    size='small'
                                                    sx={{ position: 'absolute', right: 20, top: 30 }}
                                                >
                                                    Join
                                                </Button>
                                            )
                                        )
                                    )}
                            </Box>
                        </Box>

                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, bgcolor: '#121212', padding: 2, borderRadius: '10px', alignItems: 'start', height: '100%' }}>


                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Created By:</Typography>
                                <Link style={{ textDecoration: 'none' }} to={`/profile/${group?.creator}`}><Typography sx={{ color: '#57b012', fontSize: '1rem', fontWeight: '200' }} >{group?.creator_name}</Typography></Link>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Created At:</Typography>
                                <Typography sx={{ color: '#dcdcdc', fontSize: { lg: '1rem' }, fontWeight: '200' }} >{Date(group?.date).slice(0, 10)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Total Members:</Typography>
                                <Typography sx={{ color: '#dcdcdc', fontSize: '1rem', fontWeight: '200' }} >{group?.members?.length}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Join Policy:</Typography>
                                <Typography sx={{ color: '#dcdcdc', fontSize: '1rem', fontWeight: '200' }} >{group?.type}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Approval Pending:</Typography>
                                <Typography sx={{ color: '#dcdcdc', fontSize: '1rem', fontWeight: '200' }} >{group?.joinRequests?.length}</Typography>
                            </Box>

                            {group?.discordInvitationLink && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 5 }}>
                                    <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Join our Discord Server:</Typography>
                                    <Link target='_blank' to={group?.discordInvitationLink}><Typography sx={{ color: '#7289da', fontSize: '1rem', fontWeight: '200' }} >{group?.discordInvitationLink}</Typography></Link>
                                </Box>
                            )}
                        </Box>

                    </Grid>


                </Grid>

                {(group.type === "private" || group.type === "hidden") && group?.creator === user?._id && (
                    <Box
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Typography
                            variant='h6'
                            component='h6'
                            sx={{ textAlign: 'center', mt: 3, mb: 3, color: '#dcdcdc' }}
                        >
                            Join Requests
                        </Typography>
                        <CustomDataGrid data={group?.joinRequests} columns={requestColumns} />

                    </Box>
                )}

                {
                    (group.type !== "hidden" || group?.creator === user?._id) && (
                        <Box
                            sx={{
                                // height: 800,
                                width: '100%'
                            }}
                        >
                            <Typography
                                variant='h6'
                                component='h6'
                                sx={{ textAlign: 'center', mt: 3, mb: 3, color: '#dcdcdc' }}
                            >
                                Members
                            </Typography>
                            <CustomDataGrid data={group?.members} columns={columns} handlRowClick={handlRowClick} />

                        </Box>
                    )
                }
            </Box>
        </Box >
    )
}

export default GroupDetails