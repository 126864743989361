import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../components/css/profile.css";
import CustomDataGrid from "../CustomDataGrid";
// import ViewGroup from "../ViewGroup";
// import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import CreateGroup from "../CreateGroup";

const Profile = () => {
  const columns = useMemo(() => [
    { field: 'id', headerName: 'Rank', width: 60, align: 'center', sortable: false, filterable: false, disableColumnMenu: true },
    { field: 'avatar', headerName: 'Avatar', width: 80, disableColumnMenu: true, renderCell: params => params.row.avatar ? <Avatar src={params.row.avatar} /> : <ImageRoundedIcon color='disabled' sx={{ borderRadius: '100%', width: '40px', height: '40px', padding: '5px' }} />, sortable: false, filterable: false },
    { field: 'title', headerName: 'Name', width: 180 },
    { field: 'totlalMembers', headerName: 'Members', width: 80, sortable: false, align: 'center', disableColumnMenu: true, renderCell: params => params.row.members.length },
    {
      field: 'discordInvitationLink',
      headerName: 'Discord Invitation Link',
      width: 170,
      align: 'center',
      disableColumnMenu: true,
      renderCell: params => params.row?.discordInvitationLink ? <Link to={params?.row?.discordInvitationLink} target='_blank' style={{ textDecoration: 'none' }}><Button>Join Discord</Button></Link> : "N/A",
      sortable: false,
      filterable: false,
    },
  ], []);

  const localAuth = localStorage.getItem("auth");
  const { user } = JSON.parse(localAuth);
  const [userDetails, setUserDetails] = useState(null);

  const [ownedGroups, setOwnedGroups] = useState([]);
  const [joinedGroups, setJoinedGroups] = useState([]);
  const [update, setUpdate] = useState(false);

  const params = useParams();
  const getJoinedGroups = async () => {
    try {
      const response = await axios.get(`https://community-server-livid.vercel.app/api/v1/group/user/${params.id ? params.id : user._id}`)

      if (params?.id) {
        const owned = response.data.groups.filter(group => group.creator === params.id).reverse().sort((a, b) => b.members.length - a.members.length);
        const rankedOwned = owned.map((group, index) => ({ ...group, id: index + 1 }));
        const joined = response.data.groups.filter(group => group.creator !== params.id).reverse().sort((a, b) => b.members.length - a.members.length);
        const rankedJoined = joined.map((group, index) => ({ ...group, id: index + 1 }));

        setOwnedGroups(rankedOwned);
        setJoinedGroups(rankedJoined);
      } else {

        const owned = response.data.groups.filter(group => group.creator === user._id).reverse().sort((a, b) => b.members.length - a.members.length);
        const rankedOwned = owned.map((group, index) => ({ ...group, id: index + 1 }));
        const joined = response.data.groups.filter(group => group.creator !== user._id).reverse().sort((a, b) => b.members.length - a.members.length);
        const rankedJoined = joined.map((group, index) => ({ ...group, id: index + 1 }));


        setOwnedGroups(rankedOwned);
        setJoinedGroups(rankedJoined);

      }

    } catch (error) {
      console.log(error)
    }
  }

  const getUserData = async () => {
    try {
      if (params?.id) {
        const response = await axios.get(`https://community-server-livid.vercel.app/api/v1/user/findbyid/${params?.id}`);
        setUserDetails(response.data);
      } else {
        setUserDetails(user);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getJoinedGroups();
    getUserData();
  }, [update])
  const navigate = useNavigate();

  const handlRowClick = (params) => {
    console.log(params.row._id);
    navigate(`/groups/${params.row._id}`)
  }


  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: '1200px', }} >

        <Grid container spacing={2} >

          <Grid item xs={12} lg={9} sx={{ width: '100%', position: 'relative' }}>
            <Box display={'flex'} sx={{
              flexDirection: 'row',
              width: '100%',
              bgcolor: '#121212', padding: 2, borderRadius: '10px'
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', gap: 0.5 }}>
                <Avatar sx={{ height: '120px', width: '120px' }} src={userDetails?.dId && `https://cdn.discordapp.com/avatars/${userDetails?.dId}/${userDetails?.avatar}.png`} />
                <Typography sx={{ color: '#dcdcdc', fontSize: { lg: '2rem', md: '1.5rem', xs: '1.2rem' }, fontWeight: 'bold', textAlign: 'left' }} >{user?.displayName}</Typography>
                {/* <Typography sx={{ color: 'white', fontSize: '1rem', textAlign: 'left', }}>This is group details, it can be anything. Not sure how long it can be. Not sure how long it can be. Not sure how long it can be. Not sure how long it can be.</Typography> */}

                {/* <Button size='large' sx={{ position: 'absolute', right: 20, top: 30 }}>Join</Button> */}
                <Box sx={{ position: 'absolute', right: 20, top: 30 }}>
                  <CreateGroup setUpdate={setUpdate} />

                </Box>
              </Box>
            </Box>

          </Grid>

          <Grid item xs={12} lg={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, bgcolor: '#121212', padding: 2, borderRadius: '10px', alignItems: 'start', height: '100%' }}>

              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Account created:</Typography>
                <Typography sx={{ color: '#dcdcdc', fontSize: '1rem', fontWeight: '200' }} >{Date(user?.createdAt).slice(0, 10)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Joined Groups:</Typography>
                <Typography sx={{ color: '#dcdcdc', fontSize: '1rem', fontWeight: '200' }} >{joinedGroups?.length}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography sx={{ color: '#dcdcdc', mr: 0.5, fontSize: '1rem', fontWeight: '500' }} >Created Groups:</Typography>
                <Typography sx={{ color: '#dcdcdc', fontSize: '1rem', fontWeight: '200' }} >{ownedGroups?.length}</Typography>
              </Box>
            </Box>

          </Grid>


        </Grid>
        <Box
          sx={{
            // height: 300,
            width: '100%',
          }}
        >
          <Typography
            variant='h6'
            component='h6'
            sx={{ textAlign: 'center', mt: 3, mb: 3, color: '#dcdcdc' }}
          >
            Created Groups
          </Typography>

          <CustomDataGrid data={ownedGroups} columns={columns} handlRowClick={handlRowClick} />
        </Box>


        <Box
          sx={{
            // height: '100%',
            width: '100%'
          }}
        >
          <Typography
            variant='h6'
            component='h6'
            sx={{ textAlign: 'center', mt: 3, mb: 3, color: '#dcdcdc' }}
          >
            Joined Groups
          </Typography>

          <CustomDataGrid data={joinedGroups} columns={columns} handlRowClick={handlRowClick} />
        </Box>
      </Box>
    </Box>
  )
};

export default Profile;
