import { Box, Button } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateGroup } from '../features/group/groupSlice'
import useToast from '../hooks/useToast'


const AcceptRequest = ({ params, setUpdate }) => {
    const { showToast } = useToast("");
    const dispatch = useDispatch();
    const para = useParams();
    const localAuth = localStorage.getItem("auth");
    const { user } = JSON.parse(localAuth);
    const handleAccept = async () => {
        try {
            const response = await axios.put(`https://community-server-livid.vercel.app/api/v1/group/accept/${para.id}`, { user, memberToAccept: params?.row?.user?._id });

            console.log(response.data);
            dispatch(updateGroup(response.data.group));
            showToast('Accepted Successfully');
            setUpdate(prev => !prev);
        } catch (error) {
            console.log(error);
        }
    }

    const handleCancel = async () => {
        try {
            console.log("User", user._id);
            console.log("memberToCancel", params?.row?.user?._id);
            // return;
            const response = await axios.put(`https://community-server-livid.vercel.app/api/v1/group/cancel/${para.id}`, { user, memberToCancel: params?.row?.user?._id });

            dispatch(updateGroup(response.data.group));
            showToast('Canceled Successfully');
            setUpdate(prev => !prev);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Box sx={{ m: 1, position: 'relative' }}>
            <Button
                onClick={handleAccept}
            >
                Accept
            </Button>
            <Button
                onClick={handleCancel}
            >
                Cancel
            </Button>
        </Box>
    )
}

export default AcceptRequest