import { apiSlice } from "../apiSlice";
import {
  setGroupList
} from "./groupSlice";

export const groupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupList: builder.query({
      query: (data) => ({
        url: "/api/v1/group/find",
        method: "GET",
        body: data,
      }),
      async onQueryStarted(query, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          // console.log(result);
          dispatch(setGroupList(result.data.groups));
        } catch (error) {
          console.log("redux store error", error);
        }
      },
    }),
  }),
});

export const {
  useGetGroupListQuery
} = groupApi;
