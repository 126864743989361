import { Box, Button } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateGroup } from '../features/group/groupSlice';
import useToast from '../hooks/useToast';

const KickMember = ({ params, setUpdate }) => {
    const { showToast } = useToast("");
    const dispatch = useDispatch();
    const par = useParams();
    console.log("groupId", par.id)
    const localAuth = localStorage.getItem("auth");
    const { user } = JSON.parse(localAuth);
    const handleKick = async () => {
        try {
            const response = await axios.put(`https://community-server-livid.vercel.app/api/v1/group/kick/${par.id}`, { user, memberToKick: params.row._id })
            console.log(response.data);
            dispatch(updateGroup(response.data.group));
            showToast(response.data.message);
            setUpdate(prev => !prev)
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Box sx={{ m: 1, position: 'relative' }}>
                <Button onClick={handleKick}>Kick</Button>
        </Box>
    )
}

export default KickMember