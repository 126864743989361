import { Avatar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function NavBar() {

  const navigate = useNavigate()
  const [isUser, setUsers]= useState()

  // console.log("isUser", isUser)

  useEffect(() => {
    try {
      const localAuth = localStorage?.getItem('auth');
      if (localAuth) {
        const {user} = JSON.parse(localAuth);
        if (user) {
          setUsers(user);
        } else {
          navigate('/')
        }
      }
    } catch (error) {
      console.error('Error retrieving user from local storage:', error);
    }
  }, []);

  const avatarUrl = `https://cdn.discordapp.com/avatars/${isUser?.dId}/${isUser?.avatar}.png`;
  return (
    <Box className="navbar" sx={{ width: "100%" }} >
        <Typography
          component="h1"
          variant="h5"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, color: "#dcdcdc", fontFamily: "sans-serif ", fontSize: { xs: '18px', sm: '24px', md: '30px', lg: '30px' } }}
        >
          MinePlanet Community
        </Typography>
      <Box className="avatar" display={"flex"} justifyContent={"center"} >
        <Avatar alt="Remy Sharp" src={isUser?.dId && avatarUrl}  />
      </Box>
    </Box>
  );
}

export default NavBar;
