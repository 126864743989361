import Add from "@mui/icons-material/Add";
import { Avatar, Box, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addGroup } from '../features/group/groupSlice';
import useToast from '../hooks/useToast';
import ImageUpload from './ImageUpload';

const validationSchema = {
    title: { required: "Title is required", minLength: 3, maxLength: 30 },
    description: { required: "Description is required", minLength: 10 },
    discordInvitationLink: {
        pattern: /(?:https?:\/\/)?(?:discord\.gg|discord\.com)\/([A-Za-z0-9]+)/,
        message: "Invalid Discord invitation link format"
    },
    type: { required: "Please select a group type" }
};


export default function CreateGroup({ setUpdate }) {

    const { register, handleSubmit, formState: { errors } } = useForm({ validationSchema });

    const { showToast } = useToast("");
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [discordInvitationLink, setDiscordInvitationLink] = React.useState("");
    const [type, setType] = React.useState("public");
    const [avatar, setFile] = React.useState();

    const localAuth = localStorage.getItem("auth");
    const { user } = JSON.parse(localAuth);

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const handleCreateGroup = handleSubmit((formData) => {
        formData = {
            ...formData,
            avatar: avatar,
        }; 
        axios
            .put("https://community-server-livid.vercel.app/api/v1/group/creates", { user, formData })
            .then((res) => {
                showToast("Successfully group Created!");
                setOpen(false);
                setTitle("");
                setDescription("")
                setFile("");
                setDiscordInvitationLink(""); 
                dispatch(addGroup(res.data.group))
                setUpdate(prev => !prev);

            })
            .catch((error) => {
                console.log("err",error);
            });
    });


    return (
        <React.Fragment>
            <Button
                size='small'
                variant="contained"
                color='inherit'
                sx={{ bgcolor: '#121212', color: '#dcdcdc', display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', height: '40px', gap: 0.5 }}
                onClick={handleClickOpen}
            >
                <Add sx={{ color: "#dcdcdc" }} fontSize='small' />
                New Group
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Create a new group"}
                </DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        minWidth={300}
                        sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
                    >
                        <Box>
                            <InputLabel sx={{ width: '100%' }} id="group-name">Enter your group name</InputLabel>
                            <TextField
                                sx={{
                                    mt: 1,
                                    width: '100%'
                                }}
                                label="Group name"
                                variant="outlined"
                                size='small'
                                {...register('title', validationSchema.title)}
                                error={!!errors.title}
                                helperText={errors.title?.message}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Box>
                        <Box>
                            <InputLabel sx={{ width: '100%' }} id="group-name">Enter discord invitation link (optional) </InputLabel>
                            <TextField
                                sx={{
                                    mt: 1,
                                    width: '100%'
                                }}
                                label="Discord invitation link"
                                variant="outlined" 
                                 size='small'
                                {...register('discordInvitationLink', validationSchema.discordInvitationLink)}
                                error={!!errors.discordInvitationLink}
                                helperText={errors.discordInvitationLink?.message}
                                value={discordInvitationLink}
                                onChange={(e) => setDiscordInvitationLink(e.target.value)}
                                 
                            />
                        </Box>
                        <Box>
                            <InputLabel sx={{ width: '100%' }} id="group-description">Enter your group description</InputLabel>
                            <TextField
                                sx={{
                                    mt: 1,
                                    width: '100%'
                                }}
                                label="Group description"
                                variant="outlined"
                                
                                {...register('description', validationSchema.description)}
                                error={!!errors.description}
                                helperText={errors.description?.message}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                multiline
                                rows={2}
                                maxRows={4}
                            />
                        </Box>


                        <Box>
                            <InputLabel sx={{ width: '100%' }} id="demo-simple-select-label">Who can join your group?</InputLabel>
                            <Select
                                size='small'
                                sx={{ width: '100%', mt: 1 }}
                                {...register('type', validationSchema.type)}
                                error={!!errors.type}
                                helperText={errors.type?.message}
                                value={type}
                                label="Who can join?"
                                onChange={handleChange}
                            >
                                <MenuItem value={"public"}>Public</MenuItem>
                                <MenuItem value={"private"}>Private</MenuItem>
                                <MenuItem value={"hidden"}>Private and Hidden Members</MenuItem>

                                 
                            </Select>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <InputLabel sx={{ width: '100%' }} className="mt-2">Group Image: </InputLabel>
                            <ImageUpload setAvatar={setFile} />
                        </Box>
                        <Avatar sx={{ height: '100px', width: '100px' }} variant="square" src={avatar} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleCreateGroup} autoFocus>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
