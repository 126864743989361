import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const useToast = () => {
  const showToast = (text) => {
    Toastify({
      text: text,
      gravity: "bottom",
      position: "right",
      backgroundColor:
        "linear-gradient(90deg, rgb(240, 240, 240) 0%, rgba(140, 189, 32, 1) 0%, rgba(74, 177, 14, 0.97) 100%)",
    }).showToast();
  };

  const errorToast = ({ message }) => {
    Toastify({
      text: message,
      gravity: "bottom",
      position: "right",
      backgroundColor:"red",
    }).showToast();
  };

  return {
    showToast,
    errorToast,
  };
};

export default useToast;
