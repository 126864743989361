import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Layout from "./components/Layout";
import DiscordCallback from "./components/authentication/DiscordCallback";
import SignIn from "./components/authentication/SingIn";
import SignUp from "./components/authentication/SingUp";
import GroupDetails from "./components/pages/GroupDetails";
import Home from "./components/pages/Home";
import Leaderboards from "./components/pages/Leaderboards";
import NotFound from "./components/pages/NotFound";
import Profile from "./components/pages/Profile";
import useAuthCheck from "./hooks/useAuthCheck";
function App() {
  const authChecked = useAuthCheck();
  if (authChecked) {
    return (
      <div className="App">
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute >
                <Layout />
              </PrivateRoute>
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="leaderboards" element={<Leaderboards />} /> 
            <Route path="profile" element={<Profile />} />
            <Route path="groups/:id" element={<GroupDetails />} />
            <Route path="profile/:id" element={<Profile />} />
          </Route>
          <Route path="/login" element={<SignIn />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/api/v1/user/discord/callback" element={<DiscordCallback />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    );
  } else {
    return <div 
    className="loading_"
    style={{
      color:"white",
      marginTop:"50px"
    }}>
      <span className="minePlanet">MinePlanet Community</span>
      Logging in...</div>;
  }
}

export default App;
