import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../features/auth/authApi";
import useToast from "../../hooks/useToast";

const DiscordIcon = props => {
  return (
    <svg viewBox="0 -28.5 256 256" {...props}>
      <g>
        <path
          d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
          fill="currentColor"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

const SignIn = () => {
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [error, setError] = useState("");
  const [select, setSelect] = useState(false);
  const { showToast } = useToast("");
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loginUser, { data: userData, error: responseError }] =
    useLoginUserMutation();



  useEffect(
    () => {
      if (responseError?.data?.status === "error") {
        setError(responseError?.data?.message);
        return;
      }

      if (userData?.status === "success") {
        showToast(`Login your account success!`)
        navigate("/");
      }
      if (responseError) {
        setError("User not found!");
      }
    },
    [
      navigate,
      responseError?.data?.message,
      responseError?.data?.status,
      userData?.status,
      error
    ],

  );


  const onSubmit = async (data) => {
    await loginUser(data);
    setError();
  };

  const handelOnPassword = () => {
    setPasswordStatus(passwordStatus === false ? true : false);
  };

  useEffect(() => {
    const localAuth = localStorage.getItem("auth");
    if (localAuth) {
      const auth = JSON.parse(localAuth);
      if (auth?.token && auth?.user) {
        navigate('/profile');
      }
    }
  }, [pathname]);

  return (
    <Fragment>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position_">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="box-shadow-c ">
                <div
                  className="card-header text-white text-center"
                  style={{ background: "linear-gradient(90deg,rgb(240, 240, 240) 0%,rgba(140, 189, 32, 1) 0%,rgba(74, 177, 14, 0.97) 100%)", color: "white" }}
                >

                  <span>
                    {/* <img
                                                style={{
                                                    width: '200px',
                                                    height: '60px',
                                                }}
                                                src="images/SKU-Market-Logo.svg"
                                                alt="logo"
                                                height="22"
                                            /> */}
                    <h4 className="mt-2 logo_c" >MinePlanet Community</h4>
                  </span>
                </div>

                <div className="card-body p-4 text-white"
                  style={{ background: "#1e2122" }}
                >
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-white text-center p-0 m-0 fw-bold">
                      Sign In
                    </h4>
                    <p className="p-1">
                      Enter your email address and password to login your account.
                    </p>
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3 text-left">
                      <label htmlFor="email-address" className="form-label">
                        Email address
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="email-address"
                        placeholder="Enter your email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </div>

                    <div className="mb-2 text-left">
                      <Link
                        to="/password/forgot"
                        className="text-muted float-end"
                      >
                        <small>Forgot your password?</small>
                      </Link>
                      <label
                        htmlFor="password"
                        className="form-label text-left"
                      >
                        Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type={passwordStatus ? "text" : "password"}
                          id="password"
                          className="form-control"
                          placeholder="Enter your password"
                          {...register("password", {
                            required: "Password is required",
                            minLength: 6,
                          })}
                        />
                        <div className="input-group-text p-0">
                          <Button onClick={handelOnPassword}>
                            <span className="password-eye">
                              {passwordStatus ? (
                                <VisibilityOffIcon className="OnPassword" />
                              ) : (
                                <VisibilityIcon className="OnPassword" />
                              )}
                            </span>
                          </Button>
                        </div>
                      </div>
                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </div>

                    <div className="mb-2 ">
                      <div className="form-check text-left">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="checkbox-signin"
                          checked={select}
                          onChange={() => setSelect(!select)}
                        />
                        <label
                          className="text-mute form-check-label"
                          htmlFor="checkbox-signin"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>

                    <div className="mb-0 text-center">
                      <button
                        className="btn btn-primary w-100"
                        type="submit"
                        style={{
                          background: "#1560FF",
                        }}
                      // disabled={isLoading}
                      >
                        Log In
                      </button>
                    </div>
                    <div
      className="receive_"
      style={{
          color: 'white',
        }}>
           <a href="https://mineplanet.io/tos.php" target="_blank" className="text-muted me-3 ms-5 ps-3">Terms of Use</a>
           <a href="https://mineplanet.io/privacy.php" target="_blank" className="text-muted ms-3">Privacy Policy</a>
      </div>
                  </form>

                  
                </div>
              </div>
              {/* <a
        className="d-flex-c-d"
        href="https://discord.com/oauth2/authorize?client_id=1254454978475462840&response_type=code&redirect_uri=https%3A%2F%2Fcommunity.mineplanet.io%2Fapi%2Fv1%2Fuser%2Fdiscord%2Fcallback&scope=connections+identify+email+guilds.join+guilds"
      >
        <DiscordIcon className="h-7 w-7 fill-white hover:fill-white/80 mr-4 discord_i" />
        <span className="tex-sm">Sign in with Discord</span>
      </a> */}

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    Don't have an account?{" "}
                    <Link to="/signUp" className="text-muted ms-1">
                      <b>Sign Up</b>
                    </Link>
                  </p>
                </div>
              </div>

              {error && (
                <Alert severity="error">
                  <AlertTitle>
                    <Typography pt={0.5} variant="body2" gutterBottom>
                      <strong>Check</strong> - {error ? error : ""}
                    </Typography>
                  </AlertTitle>
                </Alert>
              )}
            </div>
          </div>
        </div> 
      </div>
       
      
    </Fragment>
  );
};

export default SignIn;
