import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  
  return(<div 
  className="loading_"
  style={{
    color:"white",
    marginTop:"50px"
  }}> Not Found
   <span className="minePlanet"><Link to="/">Back to home</Link></span>
  </div>)
};

export default NotFound;
