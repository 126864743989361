import { useEffect, useRef } from 'react';
const CLOUD_NAME = "dd8putkln";
const UPLOAD_PRESET = "ehhgikou";

const ImageUpload = ({setAvatar}) => {
    const cloudinaryRef = useRef();
    const widgetRef = useRef();
    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;
        widgetRef.current = cloudinaryRef.current.createUploadWidget({
            cloudName: CLOUD_NAME,
            uploadPreset: UPLOAD_PRESET
        }, function (error, result) {
            // console.log(result.info.files.uploadInfo.url);
            console.log(result.info.url);

            if(result.info.url) setAvatar(result.info.url);
            // Handle the result or error here
        });
    }, []);


    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        widgetRef.current.open()
    }


    return (
        <div>
          <button onClick={handleClick}>
            Upload
          </button>
        </div>
      );
      
};

export default ImageUpload;
