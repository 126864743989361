import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DiscordCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = new URLSearchParams(window.location.search);
        const code = query.get('code');

        if (code) {
          const response = await axios.get(`https://community-server-livid.vercel.app/api/v1/user/discord/callback?code=${code}`);
          if (response.data.status === 'success') {
            localStorage.setItem(
                "auth",
                JSON.stringify({
                  token: response.data.token,
                  user: response.data.user,
                  id: response.data.user._id,
                })
              );
            navigate('/');
          } else {
            navigate('/login');
            console.error('Login failed:', response.data.message);
          }
        }
      } catch (error) {
        // navigate('/login');
        console.error('Error during login:', error);
      }
    };

    fetchData();
  }, [navigate]);

  return<div 
  className="loading_"
  style={{
    color:"white",
    marginTop:"50px"
  }}>
    <span className="minePlanet">MinePlanet Community</span>
    Login...</div>
};

export default DiscordCallback;
