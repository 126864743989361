import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groupList: undefined
}


const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroupList: (state, action) => {
      state.groupList = action.payload;
    },
    addGroup: (state, action) => {
      state.groupList = [...state.groupList, action.payload]
    },
    updateGroup: (state, action) => {
      const updatedGroup = action.payload;
      const groupList = state.groupList.map((group) => (group._id === updatedGroup._id ? updatedGroup : group));
      state.groupList = groupList;
    }
  },
});

export const {
  setGroupList,
  addGroup,
  updateGroup
} = groupSlice.actions;
export default groupSlice.reducer;
